import React, { useState, useEffect } from 'react'
import '../DashboardPage/sidenav.css'
import { ToastContainer, toast } from 'react-toastify';
import {ThreeDots} from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { Tooltip as Tp}  from 'react-tooltip'
import Cookies from 'js-cookie';
import noimg from '../../assets/dumLogoZem.webp'
import axios from 'axios'

const CreateChatbotDash = () => {

    const decoded = Cookies.get('accessToken');
    const token = Cookies.get('token');
    const navigate = useNavigate();
  
    const BACKEND = process.env.REACT_APP_BACKEND

   
  
    const [uploadOption, setUploadOption] = useState('url');
    const [botLogoFile, setBotLogoFile] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [botLogoUrl, setBotLogoUrl] = useState('');
    const [multiLink, setMultiLink] = useState(false);
    const [sendLink, setSendLink] = useState('');
    const [pdfFile, setPdfFile] = useState([]);
    const [ex, setEx] = useState('');
    const [exclude, setExclude] = useState('');
    const [botName, setBotName] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handleCheckboxChange = (event) => {
      setMultiLink(event.target.checked ? "Y" : "N");
    };
  
    const handleLogoChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setLogoFile(URL.createObjectURL(file));
        setBotLogoFile(file);
      }
    };
  
    const handleLogoUrlChange = (event) => {
      setBotLogoUrl(event.target.value);
      setBotLogoFile(null);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      
      const lines = ex.split('\n').map(line => line.trim()).filter(line => line !== '');
      setExclude(lines);
  
      if (botName.trim() === '') {
        toast.error('Bot name is compulsory.');
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('sendLink', sendLink);
      formData.append('exclude', lines);
      formData.append('multiLink', multiLink);
      formData.append('botName', botName);
      formData.append('uploadOption', uploadOption);
      formData.append('decoded', decoded);

  
      if (uploadOption === 'file' && botLogoFile) {
        formData.append('botLogo', botLogoFile);
      } else if (uploadOption === 'url') {
        formData.append('botLogoUrl', botLogoUrl);
      }
  
      if (pdfFile.length > 0) {
        for (let i = 0; i < pdfFile.length; i++) {
          formData.append('pdfFile[]', pdfFile[i]);
        }
      }
  
      try {
        const res = await axios.post(`${BACKEND}api/sendLinkData`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        switch (res.data) {
          case 'BotF':
            toast.error('You have finished all your bots, upgrade your subscription for more.');
            break;
          case 'FillOne':
            toast.error('At least fill one of these: PDF or website URL.');
            break;
          case 'SubE':
            toast.error('Your subscription has expired, renew for more.');
            break;
          case 'noname':
            toast.error('Bot name is compulsory.');
            break;
          case 'ok':
            toast.success('Your chatbot has been created successfully.');
            setTimeout(() => { navigate('/mychatbotsdash'); }, 2000);
            break;
          default:
            toast.error('Some error occurred!');
            console.error("Error:", res.data);
            break;
        }
      } catch (err) {
        console.error("Error in bot creation:", err);
        toast.error('API request failed!');
      } finally {
        setLoading(false);
      }
    };
    

    return (
        <div className='pb-5 responsive-side-nav-other px-0' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh', }}>
            <h1 className='pt-4 mb-4 px-3 text-white'>Create Chatbot</h1>

            <div className='px-3'>
                <p className='fs-4 col-12 d-flex justify-content-start  text-start pb-2 pt-3 mb-0' style={{ color: '#FFFFFF' }}>1. Enter the website / PDF you want to create a bot from.</p>
                <p className='fs-4 col-12 d-flex justify-content-start  text-start mb-0 pb-2 pt-3' style={{ color: '#FFFFFF' }}>2. The website / PDF will be crawled and pages extracted.</p>
                <p className='fs-4 col-12 d-flex justify-content-start  text-start mb-0 pb-5 pt-3' style={{ color: '#FFFFFF' }}>3. Go to My Chatbots page, click on your Bot, and copy the scipt to add to your website.</p>
            </div>
            <h1 className='pt-0 mb-4 px-3 text-white'>Create</h1>

            <div>
<div className='col-11 mx-auto  rounded-2' style={{backgroundColor:'#212529',color:'white'}}>
<h4 className='col-12 py-2 px-3' style={{borderBottom:'1px solid lightgrey '}}>Create Chatbot</h4>
               
{/* <form className="px-3">
  <div className="row ">
    <div className="mb-3 py-2 col-lg-6">
      <label className="form-label fw-bold">Name of Bot</label>
      <input
        type="text"
        className="form-control"
        value={botName.replace(/[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/g, '')}
        placeholder="Name of bot"
        onChange={(e) => setBot(e.target.value)}
      />
    </div>
    <div className="mb-3 py-2 col-lg-6">
      <label className="form-label fw-bold">Logo of your Website (URL of your logo)</label>
      <input
        type="text"
        className="form-control"
        value={botLogo}
        placeholder="Logo of Website"
        onChange={(e) => setBotLogo(e.target.value)}
      />
    </div>
  </div>
  <div className="row ">
    <div className="mb-3 py-2 col-lg-6">
      <label className="form-label fw-bold">Website Link you want the chatbot about (One URL Only)</label>
      <input
        type="text"
        className="form-control"
        value={sendLink}
        placeholder="website link"
        onChange={(e) => setSendLink(e.target.value)}
      />
      <div className="mb-3 d-flex align-items-center">
        <input
          className="form-check-input chkin"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          onChange={handleCheckboxChange}
        />
        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">
          Scrap data from URLs found inside the above URL.
        </label>
      </div>
      <Tp anchorSelect=".chkin" style={{ backgroundColor: "lightgrey", color: "#000000", fontWeight: 'bolder', width: '50vw', minWidth: '300px' }} place='left-start'>
        In "Off State", the chatbot will have data from only the URL provided. <br /> In "On State", the chatbot will also have data from additional webpages whose URLs it finds in the given webpage.
      </Tp>
    </div>
    <div className="mb-3 py-2 col-lg-6">
      <label className="form-label fw-bold">PDF File:</label>
      <input
        type="file"
        accept=".pdf"
        className="form-control pdf"
        multiple
        onChange={(e) => setPdfFile(e.target.files)}
      />
      <Tp anchorSelect=".pdf" style={{ backgroundColor: "lightgrey", color: "#000000", fontWeight: 'bolder', width: '30vw', minWidth: '300px' }} place="bottom">
        You can use multiple PDF files as knowledge base.
      </Tp>
    </div>
  </div>
  <div className="row ">
    <div className="mb-3 py-2 col-lg-6">
      <label className="form-label fw-bold">Pages you want to exclude from the chatbot (Write each individual link in separate line)</label>
      <textarea
        className="form-control"
        value={ex}
        placeholder="link to exclude"
        onChange={(e) => setEx(e.target.value)}
      />
    </div>
    <div className="mb-3 py-2 col-lg-6">
      <label className="form-label fw-bold col-12">Logo Preview</label>
      <img src={botLogo || noimg} style={{ height: '100px', width: '100px' }} alt="Logo Preview" />
    </div>
  </div>

</form> */}

<form className="px-3" encType="multipart/form-data" onSubmit={handleSubmit}>
      <div className="row">
        {/* Bot Name Input */}
        <div className="mb-3 py-2 col-lg-6">
          <label className="form-label fw-bold">Name of Bot</label>
          <input
            type="text"
            className="form-control"
            value={botName.replace(/[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/g, '')}
            placeholder="Name of bot"
            onChange={(e) => setBotName(e.target.value)}
          />
        </div>

        {/* Logo Option Selection */}
        <div className="mb-3 py-2 col-lg-6">
          <label className="form-label fw-bold">Choose Logo Source</label>
          <div className="d-flex">
          <div className="form-check mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="logoOption"
                id="useUrl"
                value="url"
                checked={uploadOption === 'url'}
                onChange={() => setUploadOption('url')}
              />
              <label className="form-check-label" htmlFor="useUrl">
                Use URL
              </label>
            </div>
            <div className="form-check mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="logoOption"
                id="uploadFile"
                value="file"
                checked={uploadOption === 'file'}
                onChange={() => setUploadOption('file')}
              />
              <label className="form-check-label" htmlFor="uploadFile">
                Upload File
              </label>
            </div>
           
          </div>
        </div>
      </div>

      <div className="row">
        {/* Logo File Upload or URL Input */}
        <div className="mb-3 py-2 col-lg-6">
          {uploadOption === 'file' ? (
            <>
              <label className="form-label fw-bold">Upload Logo File</label>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleLogoChange}
              />
            </>
          ) : (
            <>
              <label className="form-label fw-bold">Logo of your Website (URL)</label>
              <input
                type="text"
                className="form-control"
                value={botLogoUrl}
                placeholder="Logo URL"
                onChange={handleLogoUrlChange}
              />
            </>
          )}
        </div>

        {/* Logo Preview */}
        <div className="mb-3 py-2 col-lg-6">
          <label className="form-label fw-bold mx-2">Logo Preview</label>
          <img
            src={uploadOption === 'file' && logoFile ? logoFile : botLogoUrl || noimg}
            style={{ height: '100px', width: '100px', objectFit: 'contain' }}
            alt="Logo Preview"
          />
        </div>
      </div>

      <div className="row">
        {/* Website Link Input */}
        <div className="mb-3 py-2 col-lg-6">
          <label className="form-label fw-bold">Website Link you want the chatbot about (One URL Only)</label>
          <input
            type="text"
            className="form-control"
            value={sendLink}
            placeholder="Website link"
            onChange={(e) => setSendLink(e.target.value)}
          />
          <div className="mb-3 d-flex align-items-center">
            <input
              className="form-check-input chkin"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">
              Scrap data from URLs found inside the above URL.
            </label>
          </div>
          <Tp anchorSelect=".chkin" style={{ backgroundColor: "lightgrey", color: "#000000", fontWeight: 'bolder', width: '50vw', minWidth: '300px' }} place='left-start'>
            In "Off State", the chatbot will have data from only the URL provided. <br /> In "On State", the chatbot will also have data from additional webpages whose URLs it finds in the given webpage.
          </Tp>
        </div>

        {/* PDF Upload */}
        <div className="mb-3 py-2 col-lg-6">
          <label className="form-label fw-bold">PDF File:</label>
          <input
            type="file"
            accept=".pdf"
            className="form-control pdf"
            multiple
            onChange={(e) => setPdfFile(Array.from(e.target.files))}
          />
          <Tp anchorSelect=".pdf" style={{ backgroundColor: "lightgrey", color: "#000000", fontWeight: 'bolder', width: '30vw', minWidth: '300px' }} place="bottom">
            You can use multiple PDF files as knowledge base.
          </Tp>
        </div>
      </div>

      <div className="row">
        {/* Exclude Input */}
        <div className="mb-3 py-2 col-lg-12">
          <label className="form-label fw-bold">Urls to Exclude (New line separated)</label>
          <textarea
            className="form-control"
            value={ex}
            placeholder="Enter Urls to exclude"
            onChange={(e) => setEx(e.target.value)}
          />
        </div>
      </div>

      {/* Submit Button */}
      
    </form>


<div className="  d-flex justify-content-start align-items-center p-3 rounded-2" style={{backgroundColor:'rgb(27, 31, 37)'}}>
    <button
      className="btn btn-primary px-5  mx-2"
      onClick={(e) => handleSubmit(e)}
      disabled={botName === '' || loading}
    >
      Create Bot
    </button>
    <button
      className="btn btn-outline-secondary px-5 mx-2"
      onClick={(e) => navigate('/mychatbotsdash')}
      disabled={loading}
    >
      Cancel
    </button>
    {loading && (
      <ThreeDots type="Oval" color="#fff" height={50} width={50} />
    )}
  </div>

                </div>
            </div>


            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
        </div>
    )
}

export default CreateChatbotDash