import React, { useState,useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactPlayer from 'react-player';
import '../Homepage/gradientCss.css'

const Loader = () => (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );

const SectionOneHome = () => {

    const [loadingI, setLoadingI] = useState(true);


  


    return (
       
        <div className='sectionOneGradient' style={{ height: '100%', width: '100vw' }}>
            <div className=' mx-4 divp' >
                <h1 className=' siz flex-wrap col-12 d-flex justify-content-center text-center ' style={{ color: '#FFFFFF',fontFamily:'sans-serif' }}>AI-Native <span className='h1bubble px-4 mx-2 py-0 rounded-pill'> Knowledge </span> Management & <span className='h1bubble2 px-4 mx-2 py-0 rounded-pill'> Customer </span> Engagement Solutions</h1>
                {/* <h4 className='fw-bolder col-12 d-flex justify-content-center container pt-3' style={{ color: '#FFFFFF' }}>Demo</h4> */}
                <p className=' fw-bolder sizp col-12 d-flex justify-content-center container py-2  text-center' style={{ color: '#FFFFFF'}}>Optimize your business operations and customer interactions with our advanced AI solutions designed for effective company knowledge management and seamless customer engagement.</p>
                {/* <Link to='/create' style={{ textDecoration: 'none' }}> <button className='fw-bolder btn col-lg-3 col-sm-5 col-10 d-flex justify-content-center text-center container py-2 rounded-pill fs-5' style={{ backgroundColor: '#FFFFFF', color: '#FD7930', borderColor: 'white', textDecoration: 'none' }}>Create a Chatbot for your website</button></Link> */}
                {/* <div  className='fw-bolder btn col-lg-5 col-sm-8 col-12 d-flex justify-content-center text-center container   mt-5' style={{ backgroundColor: '#FFFFFF', color: '#FD7930', borderColor: 'white', border: '3px solid white', borderRadius: '20px' }}>
                    <ReactPlayer  url={'https://youtu.be/snYu2JUqSWs'} style={{ height: '100vh', width: '100vw' }} loop controls playing='true'/>
                </div> */}
              

            </div>
        </div>
    )
}

export default SectionOneHome