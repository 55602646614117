import * as jose from 'jose';
import Cookies from 'js-cookie';


export const getAccessToken = () => {

  const token = Cookies.get('accessToken');
  return token;
};

export const decodeToken = (token) => {
  try {
  
    const decoded = token
    return decoded;
    
  } catch (error) {
   
    return null;
  }
};

export const getAdminToken = () => {

  const adminToken= Cookies.get('adminToken');
  return adminToken;
};