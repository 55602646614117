import React,{useState,useEffect} from 'react'
import '../DashboardPage/sidenav.css'
import { ThreeDots, TailSpin } from 'react-loader-spinner';
import Cookies from 'js-cookie'
import noimg from '../../assets/dumlogo.png'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const MyChatbotsDash = () => {

    const decoded = Cookies.get('accessToken');
    const BACKEND = process.env.REACT_APP_BACKEND
    const [dataArr, setDataArr] = useState([])
    const [botId, setBotId] = useState('')
    const [apiload, setApiload] = useState(false)

    useEffect(() => {
        setApiload(true)
        axios.post(`${BACKEND}api/mybots`, { decoded }, {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
          .then(res => { setDataArr(res.data); console.log('52---', res.data); setApiload(false) }).catch(err => { console.log(" error from mybots", err); setApiload(false) })
      }, [])

  return (
    <div className='pb-5 responsive-side-nav-other px-0' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh', }}>
   
   <div className='d-flex flex-wrap pt-5 mb-5 justify-content-between px-5'>
  <h1 className=' text-white'>My Chatbots</h1>

 
  
  <button className='btn btn-primary px-4 rounded-3 d-flex align-items-center' style={{ color: '#FFFFFF', backgroundColor: '#620B84', maxWidth: '300px', height: '100%' }} variant="primary">
  <Link to='/createchatbotdash' style={{ textDecoration: 'none', color: 'white', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Create Chatbots</Link>
</button>
</div>
{
    dataArr.length === 0 && apiload === false ? 
    <h3 className=' text-white text-center'>No bot has been created by you yet.</h3>:''
  }
  {
    dataArr.length === 0 && apiload === true ? 
    <div className="mb-1 py-2 col-lg-12 d-flex justify-content-center">
    <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
</div>
    :''
  }

      <div className='d-flex justify-content-center  flex-wrap mt-5 mx-5'>


       
        {dataArr.map(x => (

          <div className='d-flex justify-content-around col-xxl-3 col-xl-4 col-lg-4 col-sm-9 col-10 mb-2 mt-5 mb-4 mx-3' style={{ width: '300px', minWidth: '250px', }}>
            <div className="card px-1" style={{ width: '500px', minWidth: '250px', }}>
              <div className="circular-image-container">
                <img className="circular-image bg-black"
                 style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'contain', // Or try 'contain', 'fill', 'scale-down' depending on your need
                  borderRadius: '50%' // Make the image circular
                }} 
                src={x.botLogo || noimg} alt="loading" />
              </div>
              <div className="card-content px-1 " style={{ height: '100%' }}>
                <p className='fs-4 col-12  d-flex justify-content-center container text-center'>{x.name}</p>
              </div>
              {
                x.NoOfCharacters === 'Infinity' ?
                  '' :
                  <div className=' col-12 d-flex justify-content-center container text-center mb-3  mt-2 fs-5 text-break' style={{ color: 'black' }}>Characters Left: {x.NoOfCharacters} </div>
              }
              <div className="card-content mt-2 py-0 px-1 " style={{}}>
                <Button value={x.id}
                  onClick={(e) => setBotId(e.target.value)}
                  className='fw-bolder px-0 mx-0 col-12 d-flex justify-content-center container text-center '
                  style={{ color: '#FFFFFF', backgroundColor: '#620B84' }}
                  variant="primary"
                >
                  <Link to={`/managebotsdash/${x.id}`} style={{ textDecoration: 'none', color: 'white', width: '100%' }}>Details</Link>
                  </Button>
              </div>
              <div className='fs-4 col-12 d-flex justify-content-center  text-center ' style={{}}>
                <p className='fs-6   fw-bold ' style={{ color: 'orange' }} >

                </p>
              </div>
            </div>
          </div>

        ))}
      </div>
     


    
    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
  </div>
  )
}

export default MyChatbotsDash