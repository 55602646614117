import React, { useState, useEffect } from 'react'
import  { useContext } from 'react';
import {Routes,Route,BrowserRouter} from 'react-router-dom'
import Home from './page/Home'
import ChatPage from './page/ChatPage'
import PricingPage from './page/PricingPage'
import AccountPage from './page/AccountPage'
import CreateBot from './page/CreateBot'
import MyChatbotsPage from './page/MyChatbotsPage'
import Login from './page/Login';
import Signup from './page/Signup';
import BlogPage from './page/BlogPage';
import PrivateRoute from './component/PrivateRoute';
import BlogIndiPage from './page/BlogIndiPage';
import SuccessPage from './page/SuccessPage';
import CancelPage from './page/CancelPage';
import PaymentHistoryPage from './page/PaymentHistoryPage'
import FeaturePage from './page/FeaturesPage'
import ScrollToTop from './component/ScrollToTop'; 
// import jwt from 'jsonwebtoken'
import * as jose from 'jose'


import { decodeToken, getAccessToken } from './component/Auth'

import Dashboard from './page/Dashboard';
import ManageBotPage from './page/ManageBotPage';
import TermsOfService from './page/TermsOfService';
import PrivacyPolicy from './page/PrivacyPolicy';
import SuperAdminPage from './page/SuperAdminPage';
import AdminOnly from './component/AdminOnly';
import UserDataSA from './page/UserDataSA';
import UserDataIndiSA from './page/UserDataIndiSA';
import UserDataBotSA from './page/UserDataBotSA';
import ForgetPassword from './page/ForgetPassword';
import ManageBotSA from './page/ManageBotSA';
import GuidePage from './page/GuidePage';
import GuidePageSA from './component/GuideSA/GuidePageSA';
import TokensLeftPage from './page/DashboardPageParts/TokensLeftPage';
import TokensLeftBotPage from './page/DashboardPageParts/TokensLeftBotPage';
import ConversationsClientDashPage from './page/DashboardPageParts/ConversationsClientDashPage';
import FailedCinversationClientPage from './page/DashboardPageParts/FailedCinversationClientPage';
import MyChatbotsDashPage from './page/DashboardPageParts/MyChatbotsDashPage';
import CreateChatbotDashPage from './page/DashboardPageParts/CreateChatbotDashPage';
import ManageBotsDashPage from './page/DashboardPageParts/ManageBotsDashPage';

const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

const App = () => {



  return (
   <div>
     <BrowserRouter>
     <ScrollToTop/>
           <Routes>
           <Route path='/' element={<Home/>}  />

           {/* dashboard client */}

           <Route path='/dashboard' element={<Dashboard/>}/>
           <Route path='/tokenleftdash' element={<TokensLeftPage/>}/>
           <Route path='/tokenleftbotdash/:id' element={<TokensLeftBotPage/>}/>

           <Route path='/conversationdash' element={<ConversationsClientDashPage/>}/>
           <Route path='/failedconversationdash' element={<FailedCinversationClientPage/>}/>
           <Route path='/mychatbotsdash' element={<MyChatbotsDashPage/>}/>
           <Route path='/createchatbotdash' element={<CreateChatbotDashPage/>}/>
           <Route path='/managebotsdash/:id' element={<ManageBotsDashPage/>}/>


             {/* dashboard client ends*/}

           <Route path='/pricing' element={<PricingPage/>}/>
           <Route path='/account' element={<PrivateRoute><AccountPage/></PrivateRoute>}/>
           <Route path='/chatpage/:id' element={<ChatPage/>}/>
           <Route path='/create' element={<PrivateRoute><CreateBot/></PrivateRoute>}/>
           <Route path='/mychatbots' element={<PrivateRoute><MyChatbotsPage/></PrivateRoute>}/>
           <Route path='/managebots/:id' element={<PrivateRoute><ManageBotPage/></PrivateRoute>}/> 
           <Route path='/blog' element={<BlogPage/>}/> 
           <Route path='/blog/:id' element={<BlogIndiPage/>}/> 
           <Route path='/terms' element={<TermsOfService/>}/> 
           <Route path='/privacy' element={<PrivacyPolicy/>}/> 
           <Route path ='/login' element={<Login/>}/>
           <Route path ='/signup' element={<Signup/>}/>
           <Route path ='/forgetpassword' element={<ForgetPassword/>}/>
           <Route path ='/SuccessPage' element={<PrivateRoute><SuccessPage/></PrivateRoute>}/>
           <Route path ='/CancelPage' element={<PrivateRoute><CancelPage/></PrivateRoute>}/>
           <Route path ='/paymenthistory' element={<PrivateRoute><PaymentHistoryPage/></PrivateRoute>}/>
           <Route path ='/feature' element={<FeaturePage/>}/>
           <Route path ='/superadmin' element={<AdminOnly><SuperAdminPage/></AdminOnly>}/>
           <Route path ='/superadminUserData' element={<AdminOnly><UserDataSA/></AdminOnly>}/>
           <Route path ='/superadminUserDataIndi/:id' element={<AdminOnly><UserDataIndiSA/></AdminOnly>}/>
           <Route path ='/superadminBot/:id' element={<AdminOnly><UserDataBotSA/></AdminOnly>}/>
           <Route path ='/superadminmanage' element={<AdminOnly><ManageBotSA/></AdminOnly>}/>
           <Route path='/guide' element={<GuidePage/>}/>
           <Route path='/superadminguide' element={<GuidePageSA/>}/>






          
        </Routes>
        </BrowserRouter>
   </div>
  )
}

export default App
