import React, { useState, useEffect } from 'react'
import '../DashboardPage/sidenav.css'
import noimg from '../../assets/dumLogoZem.webp'
import axios from 'axios'
import Accordion from 'react-bootstrap/Accordion';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { Tooltip as Tp } from 'react-tooltip'
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Cookies from 'js-cookie'
import { AiFillCheckCircle, AiFillQuestionCircle, AiFillEdit } from 'react-icons/ai'
import ChatUIDemo from '../ChatUIDemo';
import { MdDeleteForever } from "react-icons/md";

const ManageBotDash = () => {

    const { id } = useParams();
    const decoded = Cookies.get('accessToken');

    const messageStyleSend = {
        backgroundColor: '#0070DA',
        fontSize: '14px',
        // color: '#FFFFFF',
        fontFamily: 'arial',
    };
    const messageStyleRec = {
        backgroundColor: '#3D4648',
        fontSize: '14px',
        // color: '#FFFFFF',
        fontFamily: 'arial',

    };

    function CustomTooltip2({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip " style={{ backgroundColor: '#FFFFFF', color: 'black' }} >
                    <p className="label">{`${label}`}</p> <p> {`${payload[0]?.value}`}</p>
                </div>
            );
        }
        return null;
    }

    function CustomTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip " style={{ backgroundColor: '#FFFFFF', color: 'black' }} >
                    <p className="label">{`${label}`}</p> <p> {`${payload[0]?.value}`}</p>
                </div>
            );
        }
        return null;
    }

    const BACKEND = process.env.REACT_APP_BACKEND

    /////////////////////////////////////////////////////////////////////////////////// commom card

    const [loadingdel, setLoadingdel] = useState(false);
    const [togDel, setTogDel] = useState(false)


    const navigate = useNavigate();



    const handleDelete = async (e) => {
        e.preventDefault()
        setLoadingdel(true);
        await axios.post(`${BACKEND}api/deletebot/${id}`, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then((res) => { if (res.data === 'suc') { toast.success('Bot Deleted'); setLoadingdel(false); } else if (res.data === 'fail') { toast.error('Some Error Occured, Try Again'); setLoadingdel(false); } else { toast.error('Some Error Occured, Try Again'); setLoadingdel(false); } })
            .then(setTimeout(() => { navigate('/mychatbotsdash') }, 2000))
            .catch((err) => { console.log("error manage bots ", err); toast.error('API request failed!'); setLoadingdel(false); })
        //  await navigate('/mychatbots');
    }
    const handleDeleteToggle = (e) => {
        e.preventDefault()
        setTogDel(!togDel)
    }

    /////////////////////////////////////////////////////////////////////////////////// commom card end

    // ///////////////////////////////////////////////////////////////////// bot properties

    const [botname, setBot] = useState('')
    const [initialMsg, setInitialMsg] = useState('')
    const [suggestedPrompt, setSPrompt] = useState([])
    const [dataArr, setDataArr] = useState([])
    const [prompt, setPrompt] = useState('')
    const [url, setUrl] = useState('None')
    const [pdf, setPdf] = useState('None')
    const [botLogo, setBotLogo] = useState('')
    const [tokenUsedDaily, setTokenUsedDaily] = useState(0)
    const [uniqueConDaily, setUniqueConDaily] = useState(0)
    const [tokenCost, setTokenCost] = useState(0)
    const [botLogoFile, setBotLogoFile] = useState('')
    const [urlsUsed, setUrlsUsed] = useState([])
    const [urlsEx, setUrlsEx] = useState([])
    const [sources, setSources] = useState([])
    const [loading, setLoading] = useState(false);
    const [embedScript, setEmbedScript] = useState('')
    const [embeddedQA, setEmbeddedQA] = useState([])
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [apiload, setApiload] = useState(false)
    const [manageSAdata, setManageSAdata] = useState('')
    const [commonCardToken, setCommonCardToken] = useState('')
    const [commonCardCon, setCommonCardCon] = useState('')

useEffect(()=>{
console.log(sources,"---------sources")
},[sources])

    useEffect(() => {
        setApiload(true)
        axios.post(`${BACKEND}api/getmanagesa`, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { setManageSAdata(res.data[1]); setApiload(false) }).catch(err => { console.log(err); setApiload(false) })
    }, [])

    useEffect(() => {
        if (manageSAdata.embedScript != undefined) {
            setEmbedScript(manageSAdata.embedScript.replace(/\${id\.id}/g, id))
        }
    }, [manageSAdata])

    useEffect(() => {
        axios.get(`${BACKEND}api/updatebot/${id}`, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { setDataArr(res.data); console.log(res.data) })
    }, [])

    useEffect(() => {
        if(dataArr.length !== 0){
            console.log('dataARr---',dataArr)
        setBot(dataArr.botname)
        setPrompt(dataArr.prompt)
        setUrl(dataArr.url)
        setPdf(dataArr.pdf)
        setTokenCost(dataArr.tokenCost[0] === null ? 0 :dataArr.tokenCost)
        setBotLogo(dataArr.botLogo)
        setUrlsUsed(dataArr.urlsUsed)
        setUrlsEx(dataArr.urlsEx)
        setTokenUsedDaily(dataArr.TokenUsed[0])
        setUniqueConDaily(dataArr.UniqueCon[0])
        setEmbeddedQA(dataArr.embeddedQA)
        if (dataArr && dataArr.metadataUnique) {
            setSources(dataArr.metadataUnique.map(item => item.source));
        }
        setSPrompt(dataArr.sPrompt)
        setInitialMsg(dataArr.initialMsg)
        setTokenDataGraph(dataArr.tokenData)
        setConDataGraph(dataArr.UniqueConData)

        // console.log("metadata--",dataArr.metadataUnique.map(item => item.source))

        if (Array.isArray(dataArr.UniqueConData) && Array.isArray(dataArr.tokenData)) {
            console.log(dataArr.UniqueConData, "--no--149", dataArr.tokenData); // This should work
            if (dataArr.UniqueConData.length > 0 && dataArr.tokenData.length > 0) {
                console.log(dataArr.UniqueConData[0], "--ar--149", dataArr.tokenData[0]); // This should work if arrays are not empty
                // setCommonCardToken(dataArr.tokenData[0].reduce((total, entry) => total + entry.usage, 0) + tokenUsedDaily)
                // setCommonCardCon(dataArr.UniqueConData[0].reduce((total, entry) => total + entry.ConNo, 0) + uniqueConDaily)
                console.log(parseInt(dataArr.tokenData[0].reduce((total, entry) => total + entry.usage, 0), 10))
                setCommonCardToken(
                    parseInt(dataArr.tokenData[0].reduce((total, entry) => total + entry.usage, 0), 10) + parseInt(dataArr.TokenUsed[0], 10)
                );
                
                setCommonCardCon(
                    parseInt(dataArr.UniqueConData[0].reduce((total, entry) => total + entry.ConNo, 0), 10) + parseInt(dataArr.UniqueCon[0], 10)
                );
            } else {
                console.error("One or both arrays are empty.");
            }
        } else {
            console.error("UniqueConData or tokenData is not an array.");
        }
    }
    }, [dataArr])

    const toggleAccordion2 = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };


    const handleCopyClick = () => {
        const textArea = document.createElement('textarea');
        textArea.value = embedScript;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        toast.success('Script Copied', 3000)
    };

    const [uploadOption, setUploadOption] = useState('url');
    const [logoFile, setLogoFile] = useState(null);
  
    const handleFileChange = (e) => {
      setLogoFile(e.target.files[0]);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
  
      const formData = new FormData();
      formData.append('botname', botname);
      formData.append('prompt', prompt);
      formData.append('initialMsg', initialMsg);
      formData.append('uploadOption', uploadOption);
      formData.append('suggestedPrompt', JSON.stringify(suggestedPrompt));
      if (uploadOption === 'file' && logoFile) {
        formData.append('botLogo', logoFile);
      } else if (uploadOption === 'url') {
        formData.append('botLogoUrl', botLogo);
      }
  
      axios.post(`${BACKEND}api/updatebot/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res) => {
          if (res.data === 'noname') {
            toast.error("Bot name can't be empty");
          } else {
            toast.success('Updated Successfully!');
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("error manage bots ", err);
          toast.error('API request failed!');
          setLoading(false);
        });
    };
  

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     setLoading(true);
    //     axios.put(`${BACKEND}api/updatebot/${id}`, { botname, prompt, initialMsg, suggestedPrompt, botLogo }, {
    //         'Content-type': 'application/json',
    //         'Accept': 'application/json',
    //         'Access-Control-Allow-Origin': '*'
    //     }).then((res) => { if (res.data === 'noname') { toast.error("Bot name can't be empty"); setLoading(false); } else { toast.success('Updated Successfully!'); setLoading(false); } }).catch((err) => { console.log("error manage bots ", err); toast.error('API request failed!'); setLoading(false); })
    // }


    ///////////////////////////////////////////////////////////////////// bot properties end


    //////////////////////////////////////////////////////////////// retrain bot

   

    const [sendLink, setSendLink] = useState('')
    const [ex, setEx] = useState('')
    const [pdfFile, setPdfFile] = useState(null);
    const [loadingre, setLoadingre] = useState(false);
    const [exclude, setExclude] = useState('')
    const [togRetrain, setTogRetrain] = useState(false)
    const [multiLink, setMultiLink] = useState(false);
    const [loadingMetaDel, setLoadingMetaDel] = useState(false);

    const handleRetrainToggle = (e) => {
        e.preventDefault()
        setTogRetrain(!togRetrain)
    }

    const handleCheckboxChange = (event) => {
        const checkbox = event.target;
        if (checkbox.checked) {
            setMultiLink("Y")
        } else {
            setMultiLink("N")
        }
    };

    const [addPdf, setAddPdf] = useState("N");
    const handleCheckboxChangePdf = (event) => {
        const checkbox = event.target;
        if (checkbox.checked) {
            setAddPdf("Y")
        } else {
            setAddPdf("N")
        }
    };

    const handleRetrain = async (e) => {
        e.preventDefault()
        setLoadingre(true);
        const lines = ex.split('\n');
        const trimmedLines = lines.map((line) => line.trim());
        const filteredLines = trimmedLines.filter((line) => line !== '');
        setExclude(filteredLines);
        setTogRetrain(!togRetrain)
    }

    useEffect(() => {
        if (sendLink !== '' || exclude !== '' || pdfFile !== null) {
            const formData = new FormData();
            formData.append('sendLink', sendLink);
            formData.append('exclude', exclude);
            formData.append('multiLink', multiLink);
            formData.append('addDataToExisting', addPdf);

            // formData.append('pdfFile', pdfFile);
            if (pdfFile) {
                for (let i = 0; i < pdfFile.length; i++) {
                    formData.append(`pdfFile[]`, pdfFile[i]);
                }
            }
            console.log(id, "---245")

            axios.post(`${BACKEND}api/updateLinkData/${id}`, formData, {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            })
                .then(res => {
                    if (res.data == 'BotF') { toast.error('You have Finished all your Bots, upgrade subscription for more'); setLoadingre(false); }
                    else if (res.data == 'FillOne') { toast.error('Atleast fill one of these, PDF or website URL'); console.log("ressssssssssssss", res.data); setLoadingre(false); }
                    else if (res.data == 'SubE') { toast.error('Your Subscription has Expired, renew subscription for more'); setLoadingre(false); }
                    else if (res.data == 'noname') { toast.error('Botname is compulsary'); setLoadingre(false); }
                    else if (res.data === 'ok') { toast.success('Bot Retrained Successfully!'); setLoadingre(false); setTimeout(() => { window.location.reload(true) }, 2000) }
                    else { toast.error('Some Error Occured!!'); console.log(res.data, "CHKKKKKKKKKKKK"); setLoading(false); }
                })
                .catch(err => { console.log("error  botsection 1 ", err); toast.error('API request failed!'); setLoadingre(false); })
        }
    }, [exclude])

    const [loadingIndex, setLoadingIndex] = useState(null);


   const handleRemoveClick=(e,x,index)=>{
    console.log("xxxxxx,",x,index)
   
    handleRemoveMetadata(x)
    handleEmbedSavedRemoveRDNF(e,x)
    handleEmbedSavedRemove(e,x)
    setLoadingIndex(null);   
}

   const findFullObjectByQuestion = (question) => {
    return embeddedQA.find(item => item.question === question);
  }

   const handleEmbedSavedRemove = (e, x) => {
    e.preventDefault()

    const qa = findFullObjectByQuestion(x)
    let queE=''
    let ansE=''
    if(qa?.question){
     queE = qa.question
     ansE = qa.answer
    }else{
        console.log('in side')
        return 'ok'
    }

   console.log("aaaa",queE,ansE,qa)

    axios.post(`${BACKEND}api/embedQuestionSavedRemove/${id}`, { queE, ansE }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'success') { toast.success('Removed'); } else { toast.error("Some Error Occured"); } }).catch(err => { console.log(err); toast.error("Some Error Occured !!!!"); })
  }


   const handleEmbedSavedRemoveRDNF = (e, qa) => {
    e.preventDefault()

    // if (!buttonClicked[qa.question]) {
    //   setButtonClicked((prevState) => ({
    //     ...prevState,
    //     [qa.question]: true,
    //   }));
    // }

    const queE = qa




    axios.post(`${BACKEND}api/embedQuestionSavedRemoveRDNF/${id}`, { queE }, {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }).then(res => { if (res.data === 'success') { toast.success('Removed'); } 
    else { toast.error("Some Error Occured");  } })
    .catch(err => { console.log(err); toast.error("Some Error Occured !!!!");  })
  }


    const handleRemoveMetadata = (data) => {
        // e.preventDefault()
        console.log(data,"----data")
        setLoadingMetaDel(true)
        axios.post(`${BACKEND}api/deletemetadata/${id}`, { data }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { if (res.data[0] == 'Error') { console.log(res.data[1]); toast.error('Some Error Occured!!!'); setLoadingMetaDel(false) } else if (res.data == 'ok') { toast.success("Data Removed"); setTimeout(() => { window.location.reload(true) }, 1000); setLoadingMetaDel(false) } else { console.log(res.data); toast.error("Some Error Occured"); setLoadingMetaDel(false) } }).catch(err => { toast.error('Some Error Occured'); console.log(err); setLoadingMetaDel(false) })

    }


    ////////////////////////////////////////////////////////////////// retrain bot end



    ////////////////////////////////////////////////////////////////////////////////  bot analytics
    const itemsPerPage = 10;
    const [tokenDataGraph, setTokenDataGraph] = useState([])
    const [vis, setVis] = useState('Bot Properties')
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedTimeCon, setSelectedTimeCon] = useState('');
    const [minHeight, setMinHeight] = useState(500);
    const [conDataGraph, setConDataGraph] = useState([])
    const [filterHistory, setFilterHistory] = useState([])
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [searchTermPay, setSearchTermPay] = useState([]);
    const [sortedHistory, setSortedHistory] = useState([]);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [history, setHistory] = useState('')

    const itemsToDisplay = sortedHistory.slice(startIndex, endIndex);



    useEffect(() => {
        if (filterHistory.length !== 0) {
            const sortedData = Array.isArray(filterHistory)
                ? filterHistory.sort((a, b) => new Date(b.time) - new Date(a.time))
                : [];

            setSortedHistory(sortedData);
            setCurrentPage(1)
        }
    }, [filterHistory]);

    const toggleAccordion = (index) => {
        if (openAccordionIndex === index) {
            setOpenAccordionIndex(null);
        } else {
            setOpenAccordionIndex(index);
        }
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= Math.ceil(sortedHistory.length / itemsPerPage)) {
            setCurrentPage(page);
        }
    };

    useEffect(() => {
        setFilterHistory(history)
    }, [history])

    const handleCreateGraph = () => {
        console.log("chart tokem")
        axios.post(`${BACKEND}api/chartToken/${id}`, { selectedTime }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { if (res.data === 'nodata') { console.log(res.data); toast.error("Data Doesn't exist for this date ") } else if (res.data[0] == 'error') { console.log(res.data[1]); toast.error("Error Fetching data"); } else { setTokenDataGraph(res.data) } })
            .catch(err => console.log(err))
    }

    const handleCreateGraphCon = () => {
        console.log("chart con")
        axios.post(`${BACKEND}api/chartCon/${id}`, { selectedTimeCon }, {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }).then(res => { if (res.data === 'nodata') { console.log(res.data); toast.error("Data Doesn't exist for this date "); } else if (res.data[0] == 'error') { console.log(res.data[1]); toast.error("Error Fetching data "); } else { setConDataGraph(res.data) } })
            .catch(err => console.log(err))
    }

    const handleSearchPay = (e) => {
        console.log(e, "--118")
        const input = e.target.value;
        setSearchTermPay(input);
        const filtered = history.filter((item) => {
            const regex = new RegExp(input, 'i');
            for (const key in item) {
                if (item.hasOwnProperty(key) && regex.test(item[key])) {
                    return true;
                }
            }
            return false;
        });
        setFilterHistory(filtered);
    };

    useEffect(() => {
        if (vis === 'Bot Analytics') {
            axios.get(`${BACKEND}api/historyget/${id}`, {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }).then(res => { if (res.data[0] == 'error') { console.log("321----", res.data[1]) } else { setHistory(res.data); } }).catch(err => console.log(err))
        }
    }, [vis])

    useEffect(() => {
        if (vis === 'Bot Analytics') {
            handleCreateGraph()
            handleCreateGraphCon()
        }
    }, [vis])

    ////////////////////////////////////////////////////////////////////////////////  bot analytics end

    return (
        <div className='pb-5 responsive-side-nav-other px-2' style={{ backgroundColor: '#171725', height: '100%', minHeight: '100vh' }}>

            <h1 className='pt-4 mb-4 text-white'>Manage Chatbot</h1>

            <div className='row'>
                <div className='col-xl-3 col-12 my-2 mx-auto' style={{ maxWidth: '95%' }}>

                    <div style={{
                        border: '1px solid #ddd',
                        borderRadius: '10px',
                        padding: '20px',
                        textAlign: 'center',
                    }}>
                        {/* <div className="circular-image-container"> */}
                        <img
                            src={botLogo || noimg}
                            alt="Profile"
                            style={{ 
                                width: '100px', 
                                height: '100px', 
                                objectFit: 'contain', // Or try 'contain', 'fill', 'scale-down' depending on your need
                                borderRadius: '50%' // Make the image circular
                              }} 
                        />
                        {/* </div> */}
                        <h4 style={{ color: 'white', margin: '0 0 10px 0' }} className=''>{botname}</h4>
                        <div className="mb-1 py-2 col-lg-12 d-flex justify-content-center">
                                                    {apiload && <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />}
                                                </div>
                        <hr style={{ color: 'white' }} />
                        <h5 style={{ color: 'white', }} className='mb-2 mt-4 text-start'>Token Usage: {commonCardToken}</h5>
                        <hr style={{ color: 'white' }} />
                        <h5 style={{ color: 'white', }} className='my-2 text-start'>Conversations: {commonCardCon}</h5>
                        <hr style={{ color: 'white' }} />
                        <h5 style={{ color: 'white', }} className='my-2 text-start'>Cost per month: {tokenCost} $</h5>
                        <hr style={{ color: 'white' }} />
                        <h5
                            className='d-flex justify-content-start  text-start mb-3'
                            style={{ height: '100%', color: '#FFFFFF' }}
                        >
                            Add this Script to your website to get your chatbot
                        </h5>
                        <Tp
                            anchorSelect=".my-anchor-element-script"
                            style={{ backgroundColor: "rgba(255, 255, 255,1)", color: "#000000", fontWeight: 'bolder' }}
                            place="top"
                        >
                            Click to Copy the script
                        </Tp>
                        <textarea
                            id='textareascript'
                            className=' d-flex justify-content-center my-anchor-element-script  text-center mb-3'
                            readOnly
                            style={{ height: '120px', width: '100%', fontSize: '13px' }}
                            placeholder='Script'
                            value={embedScript}
                            onClick={handleCopyClick}
                        />
                        <hr style={{ color: 'white' }} />
                        <div className='form-group mt-3'>
                            <button className='btn btn-outline-danger px-5 ' onClick={(e) => handleDeleteToggle(e)}>Delete Bot</button>
                        </div>
                        {togDel ?
                            <div className='form-group mt-3'>
                                <button className='btn btn-danger mx-1 ' onClick={(e) => handleDelete(e)}>Delete</button>
                                <button className='btn btn-secondary mx-1 ' onClick={(e) => handleDeleteToggle(e)}>No</button>
                            </div> : ''
                        }
                        <div className='form-group d-flex justify-content-center'>
                            {loadingdel ? (
                                <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div style={{}} className='col-xl-9 col-12 my-2'>
                    <div style={{ border: '1px solid #ddd', color: 'white', backgroundColor: '#212529', maxWidth: '95%', padding: '1.5px' }} className='rounded-3 mx-auto'>
                        {/* Header Row */}
                        <div className='flex-wrap' style={{ display: 'flex', flexWrap: 'wrap', borderBottom: '2px solid #ccc', backgroundColor: '#212529' }}>
                            {['Bot Properties', 'Retrain Chatbot', 'Chatbot Preview', 'Bot Analytics'].map((item) => (
                                <div
                                    key={item}
                                    className='btn-link my-2 mx-1 rounded-3 text-center'
                                    onClick={() => setVis(item)}
                                    style={{
                                        flex: '1 1 22%', // Default to 4 items in a row
                                        padding: '10px',
                                        textAlign: 'left',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        color: vis === item ? 'white' : 'white',
                                        backgroundColor: vis === item ? '#007BFF' : '#212529',
                                        margin: '0.5%',// Small margin to help with wrapping
                                        minWidth: '140px'
                                    }}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>

                        <style>
                            {`
    @media (max-width: 1200px) {
      .btn-link {
        flex: 1 1 45%; // 2 items in a row
      }
    }
    @media (max-width: 600px) {
      .btn-link {
        flex: 1 1 100%; // 1 item per row
      }
    }
  `}
                        </style>

                        {/* Data Rows */}
                        {
                            vis === 'Bot Properties' ?

                                <div className=''>

                                    <div>

                                        <div className="pt-3 pb-2 px-3" style={{ borderBottom: "1px solid grey" }}>
                                            <a href="#updateedit" style={{ maxWidth: '100px' }} className='btn btn-secondary d-flex align-items-center ms-auto'>
                                                <AiFillEdit className='me-2' style={{ color: 'white', fontSize: '1.2rem' }} />
                                                Edit
                                            </a>
                                        </div>


                                        <div className="pt-3 pb-2 mx-3" style={{ borderBottom: "1px solid grey" }}>
                                            <h4
                                                className="fs-4 d-flex justify-content-start container text-start mb-1"
                                                style={{ height: "100%", color: "#FFFFFF" }}
                                            >
                                                URL Used
                                            </h4>
                                            <div
                                                className="fs-5 d-flex justify-content-start container text-start "
                                                style={{ width: "100%", minHeight: "50px", wordBreak:'break-word' }}
                                            >
                                                {url}
                                            </div>
                                        </div>

                                        <div className="pt-3 pb-2 mx-3" style={{ borderBottom: "1px solid grey" }}>
                                            <h4
                                                className="fs-4 d-flex justify-content-start container text-start mb-1"
                                                style={{ height: "100%", color: "#FFFFFF", wordBreak:'break-word' }}
                                            >
                                                PDF Used
                                            </h4>
                                            <div style={{ width: "95%", minHeight: "50px" }}>
                                                {Array.isArray(pdf) && pdf.length > 0 ? (
                                                    pdf.map((item, index) => (
                                                        <div key={index} style={{wordBreak:'break-word'}} className="fs-5 d-flex justify-content-start container text-start">
                                                            {String(item)}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="fs-5 d-flex justify-content-start container text-start">N/A</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="pt-3 pb-2 mx-3" style={{ borderBottom: "1px solid grey" }}>
                                            <h4
                                                className="fs-4 d-flex justify-content-start container text-start mb-1"
                                                style={{ height: "100%", color: "#FFFFFF" }}
                                            >
                                                URLs Scrapped
                                            </h4>
                                            <div style={{ width: "95%", minHeight: "50px" }}>
                                                {Array.isArray(urlsUsed) && urlsUsed.length > 0 ? (
                                                    urlsUsed.map((url, index) => (
                                                        <div key={index} style={{wordBreak:'break-word'}} className="fs-5 d-flex justify-content-start container text-start">
                                                            {url}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="fs-5 d-flex justify-content-start container text-start">N/A</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="pt-3 pb-2 mx-3" style={{ borderBottom: "1px solid grey" }}>
                                            <h4
                                                className="fs-4 d-flex justify-content-start container text-start mb-1"
                                                style={{ height: "100%", color: "#FFFFFF" }}
                                            >
                                                URLs Excluded
                                            </h4>
                                            <div style={{ width: "95%", minHeight: "50px" }}>
                                                {Array.isArray(urlsEx) && urlsEx.length > 0 ? (
                                                    urlsEx.map((url, index) => (
                                                        <div key={index} style={{wordBreak:'break-word'}} className="fs-5 d-flex justify-content-start container text-start">
                                                            {url === '' ? 'N/A' : url}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="fs-5 d-flex justify-content-start container text-start">N/A</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="pt-3 pb-2 mx-3" style={{ borderBottom: "1px solid grey" }}>
                                            {embeddedQA && embeddedQA.length > 0 && (
                                                <Accordion
                                                    activeKey={isAccordionOpen ? "0" : null}
                                                    onSelect={toggleAccordion2}
                                                    className="col-12 my-4 mx-auto custom-accordion"
                                                    style={{ marginBottom: "100px" }}
                                                >
                                                    <Accordion.Item
                                                        eventKey="0"
                                                        style={{ backgroundColor: "#212529", border: "1px solid #171725" }}
                                                    >
                                                        <Accordion.Header>
                                                            <div
                                                                className="d-flex justify-content-start align-items-center fs-4 container text-start"
                                                                style={{ height: "100%", color: "#FFFFFF" }}
                                                            >
                                                                Embedded Q/A
                                                                <span
                                                                    style={{
                                                                        color: "#FFE459",
                                                                        position: "absolute",
                                                                        right: "25px",
                                                                        top: "50%",
                                                                        fontSize: "34px",
                                                                        transform: "translateY(-50%)",
                                                                    }}
                                                                >
                                                                    {isAccordionOpen ? "-" : "+"}
                                                                </span>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body style={{ color: "white", backgroundColor: "#171725" }}>
                                                            {embeddedQA.map((item, index) => (
                                                                <div key={index} className="my-3">
                                                                    <div className="mb-2">
                                                                        {/* <h5 className="fs-4 text-start mb-1" style={{ color: "#FFE459" }}>Question</h5> */}
                                                                        <div className="fs-4 text-start" style={{ color: "yellow" }}>
                                                                            <AiFillQuestionCircle className='mb-1 me-2' style={{ color: 'yellow' }} />{item.question}
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-2">
                                                                        {/* <h5 className="fs-4 text-start mb-1" style={{ color: "#FFE459" }}>Answer</h5> */}
                                                                        <div className="fs-5 text-start" style={{ color: "#FFFFFF" }}>
                                                                            <AiFillCheckCircle className='mb-1 me-2' style={{ color: 'white' }} />{item.answer}
                                                                        </div>
                                                                    </div>
                                                                    {index < embeddedQA.length - 1 && <hr style={{ width: "95%", margin: "0 auto", borderColor: "#FFE459" }} />}
                                                                </div>
                                                            ))}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            )}
                                        </div>


                                    </div>

                                    {/* update bot */}
                                    {/* <div id='updateedit' className='fs-4 mx-3 rounded-3 d-flex justify-content-center  text-center mb-5 mt-3' style={{ color: '#FFFFFF', backgroundColor: '#242439', border: '1px solid grey' }}>

                                        <form className="px-3 col-12">
                                            <h3
                                                className="fs-4 d-flex justify-content-center container text-center mt-3 mb-1"
                                                style={{ color: "#FFFFFF" }}
                                            >
                                                Edit
                                            </h3>
                                            <div className="row">
                                                <div className="mb-3 py-2 col-lg-6">
                                                    <label htmlFor="botname" className="form-label fs-6">Name of Chatbot</label>
                                                    <input
                                                        id="botname"
                                                        type="text"
                                                        className="form-control"
                                                        value={botname}
                                                        placeholder="Name of the bot"
                                                        onChange={(e) => setBot(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3 py-2 col-lg-6">
                                                    <label htmlFor="initialMsg" className="form-label fs-6">Initial message from the Chatbot</label>
                                                    <input
                                                        id="initialMsg"
                                                        type="text"
                                                        className="form-control"
                                                        value={initialMsg}
                                                        placeholder="Initial message on starting the chat"
                                                        onChange={(e) => setInitialMsg(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mb-3 py-2 col-lg-6">
                                                    <label htmlFor="prompt" className="form-label fs-6">Prompt (Don't remove text and query from the prompt)</label>
                                                    <textarea
                                                        id="prompt"
                                                        className="form-control"
                                                        value={prompt}
                                                        placeholder="Enter your prompt here"
                                                        onChange={(e) => {
                                                            setPrompt(e.target.value);
                                                            e.target.style.height = 'auto';
                                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-3 py-2 col-lg-6">
                                                    <label htmlFor="suggestedPrompt" className="form-label fs-6">Suggested Queries for the Chatbot (Seperated by a comma)</label>
                                                    <textarea
                                                        id="suggestedPrompt"
                                                        className="form-control"
                                                        value={suggestedPrompt}
                                                        placeholder="Suggested Prompts (separate each with ',')"
                                                        onChange={(e) => {
                                                            setSPrompt(e.target.value.split(","));
                                                            e.target.style.height = 'auto';
                                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mb-3 py-2 col-lg-6">
                                                    <label htmlFor="botLogo" className="form-label fs-6">Logo of your Website (URL of your Logo)</label>
                                                    <input
                                                        id="botLogo"
                                                        type="text"
                                                        className="form-control"
                                                        value={botLogo}
                                                        placeholder="URL of the logo image"
                                                        onChange={(e) => setBotLogo(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3 py-2 col-lg-6">
                                                    <label className="form-label fs-6  col-12">Logo Preview</label>
                                                    <img src={botLogo || noimg} style={{ height: '100px', width: '100px', objectFit: 'contain' }} alt="Logo Preview" />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mb-3 py-2 col-lg-12">
                                                    <button type="submit" className="btn btn-outline-warning px-5" onClick={(e) => handleSubmit(e)}>Update Bot</button>
                                                </div>

                                                <div className="mb-3 py-2 col-lg-12 d-flex justify-content-center">
                                                    {loading ? (
                                                        <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </form>
                                    </div> */}

<div id='updateedit' className='fs-4 mx-3 rounded-3 d-flex justify-content-center text-center mb-5 mt-3' style={{ color: '#FFFFFF', backgroundColor: '#242439', border: '1px solid grey' }}>
      <form className="px-3 col-12" onSubmit={handleSubmit}>
        <h3 className="fs-4 d-flex justify-content-center container text-center mt-3 mb-1" style={{ color: "#FFFFFF" }}>Edit</h3>
        <div className="row">
          <div className="mb-3 py-2 col-lg-6">
            <label htmlFor="botname" className="form-label fs-6">Name of Chatbot</label>
            <input
              id="botname"
              type="text"
              className="form-control"
              value={botname}
              placeholder="Name of the bot"
              onChange={(e) => setBot(e.target.value)}
            />
          </div>
          <div className="mb-3 py-2 col-lg-6">
            <label htmlFor="initialMsg" className="form-label fs-6">Initial message from the Chatbot</label>
            <input
              id="initialMsg"
              type="text"
              className="form-control"
              value={initialMsg}
              placeholder="Initial message on starting the chat"
              onChange={(e) => setInitialMsg(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-3 py-2 col-lg-6">
            <label htmlFor="prompt" className="form-label fs-6">Prompt (Don't remove text and query from the prompt)</label>
            <textarea
              id="prompt"
              className="form-control"
              value={prompt}
              placeholder="Enter your prompt here"
              onChange={(e) => {
                setPrompt(e.target.value);
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
          </div>
          <div className="mb-3 py-2 col-lg-6">
            <label htmlFor="suggestedPrompt" className="form-label fs-6">Suggested Queries for the Chatbot (Separated by a comma)</label>
            <textarea
              id="suggestedPrompt"
              className="form-control"
              value={suggestedPrompt.join(',')}
              placeholder="Suggested Prompts (separate each with ',')"
              onChange={(e) => {
                setSPrompt(e.target.value.split(","));
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
          </div>
        </div>
        <div className="row">
  <div className="mb-3 py-2 col-lg-6">
    <label className="form-label fs-6">Upload Logo As</label>
    <div>
      <label className='mx-2 '>
        <input
        className='mx-2'
        style={{height:'20px',width:'20px'}}
          type="radio"
          value="url"
          checked={uploadOption === 'url'}
          onChange={() => setUploadOption('url')}
        />
        URL
      </label>
      <label className='mx-2 '>
        <input
        className='mx-2'
        style={{height:'20px',width:'20px'}}
          type="radio"
          value="file"
          checked={uploadOption === 'file'}
          onChange={() => setUploadOption('file')}
        />
        File
      </label>
    </div>
  </div>

  {uploadOption === 'url' && (
    <div className="mb-3 py-2 col-lg-6">
      <label htmlFor="botLogo" className="form-label fs-6">Logo of your Website (URL of your Logo)</label>
      <input
        id="botLogo"
        type="text"
        className="form-control"
        value={botLogo}
        placeholder="URL of the logo image"
        onChange={(e) => setBotLogo(e.target.value)}
      />
    </div>
  )}

  {uploadOption === 'file' && (
    <div className="mb-3 py-2 col-lg-6">
      <label htmlFor="botLogoFile" className="form-label fs-6">Upload Logo File</label>
      <input
        id="botLogoFile"
        type="file"
        className="form-control"
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  )}

  <div className="mb-3 py-2 col-lg-6">
    <label className="form-label fs-6 col-12">Logo Preview</label>
    <img
      src={
        uploadOption === 'url' && botLogo ? botLogo :
        uploadOption === 'file' && logoFile ? URL.createObjectURL(logoFile) :
        ''
      }
      style={{ height: '100px', width: '100px', objectFit: 'contain' }}
      alt="Logo Preview"
    />
  </div>
</div>
        <div className="row">
          <div className="mb-3 py-2 col-lg-12">
            <button type="submit" className="btn btn-outline-warning px-5">Update Bot</button>
          </div>
          <div className="mb-3 py-2 col-lg-12 d-flex justify-content-center">
            {loading ? (
              <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
            ) : null}
          </div>
        </div>
      </form>
    </div>



                                </div> : ''
                        }
                        {
                            vis === 'Retrain Chatbot' ?

                                <div className='px-3'>

                                    <div className='px-0 ' style={{ borderBottom: "1px solid grey" }}>
                                        <h3 className="fs-4 d-flex justify-content-center container text-center mt-3 mb-1" style={{ color: "#FFFFFF" }}>
                                            Retrain Your Chatbot
                                        </h3>
                                        <form className='col-12'>
                                            <div className="row">
                                                <div className="mb-0 pt-2 col-lg-12">
                                                    <label htmlFor="websiteLink" className="form-label fs-6">Website Link for Chatbot</label>
                                                    <input
                                                        id="websiteLink"
                                                        className="form-control"
                                                        type="text"
                                                        value={sendLink}
                                                        placeholder='Enter website link'
                                                        onChange={(e) => setSendLink(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-4 py-2 col-lg-12 d-flex align-items-center">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        id="scrapDataCheckbox"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="scrapDataCheckbox">
                                                        Scrap data from URLs found inside the above URL
                                                    </label>
                                                    <Tp
                                                        anchorSelect=".my-anchor-element"
                                                        style={{ backgroundColor: "rgba(255, 255, 255,1)", color: "#000000", fontWeight: 'bolder', width: '90vw' }}
                                                        place="top"
                                                    >
                                                        In "Off State", the chatbot will have data from only the URL provided. <br />
                                                        In "On State", the chatbot will also have data from additional webpages whose URLs it finds in the given webpage.
                                                    </Tp>
                                                </div>

                                                <div className="mb-4 py-2 col-lg-12">
                                                    <label htmlFor="excludeLinks" className="form-label fs-6">Pages to Exclude (Enter each link on a new line)</label>
                                                    <textarea
                                                        id="excludeLinks"
                                                        className="form-control"
                                                        value={ex}
                                                        placeholder='Enter links to exclude'
                                                        onChange={(e) => {
                                                            setEx(e.target.value);
                                                            e.target.style.height = 'auto';
                                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-0 pt-2 col-lg-12">
                                                    <label htmlFor="pdfFile" className="form-label fs-6">PDF File(s):</label>
                                                    <input
                                                        id="pdfFile"
                                                        type="file"
                                                        accept=".pdf"
                                                        className="form-control"
                                                        onChange={(e) => setPdfFile(e.target.files)}
                                                        multiple
                                                    />
                                                    <Tp
                                                        anchorSelect=".my-anchor-element-pdf"
                                                        style={{ backgroundColor: "rgba(255, 255, 255,1)", color: "#000000", fontWeight: 'bolder', width: '90vw' }}
                                                        place="top"
                                                    >
                                                        You can upload multiple PDF files as a knowledge base.
                                                    </Tp>
                                                </div>

                                                <div className="mb-4 py-2 col-lg-12 d-flex align-items-center">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        id="addDataCheckbox"
                                                        onChange={handleCheckboxChangePdf}
                                                    />
                                                    <label className="form-check-label" htmlFor="addDataCheckbox">
                                                        Add data to existing knowledge base
                                                    </label>
                                                    <Tp
                                                        anchorSelect=".my-anchor-element-add"
                                                        style={{ backgroundColor: "rgba(255, 255, 255,1)", color: "#000000", fontWeight: 'bolder', width: '90vw' }}
                                                        place="top"
                                                    >
                                                        In 'OFF' state, the knowledge base will be replaced by new data. <br />
                                                        In 'ON' state, the new data will be added to the existing knowledge base.
                                                    </Tp>
                                                </div>

                                                <div className="mb-1 py-2 col-lg-12 d-flex justify-content-around flex-wrap">
                                                    <button className='btn btn-outline-warning px-5' onClick={(e) => handleRetrainToggle(e)}>Retrain Bot</button>
                                                    {togRetrain && (
                                                        <div className='d-flex mt-3 col-12 d-flex justify-content-center'>
                                                            <button className='btn btn-danger mx-1' onClick={(e) => handleRetrain(e)}>Retrain</button>
                                                            <button className='btn btn-secondary mx-1' onClick={(e) => handleRetrainToggle(e)}>No</button>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="mb-1 py-2 col-lg-12 d-flex justify-content-center">
                                                    {loadingre && <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />}
                                                </div>
                                            </div>

                                        </form>
                                    </div>

                                    <div className='row rounded-4 col-12 mx-auto mt-4 mb-3 d-flex' style={{ border: "1px solid grey" }}>

                                        <Card style={{ backgroundColor: sources.length !== 0 ? '#242439' : '#171725' }} className='d-flex rounded-4'>
                                            <h3 className='mt-4 text-center' style={{ color: 'white' }}>Sources Used in Chatbot's Knowledge Base</h3>
                                            <Card.Body className="d-flex flex-column px-0">
                                                <Table bordered hover variant="dark" className='rounded-4'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Data Source</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sources.length !==0 ? sources.map((x, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td style={{ wordBreak: 'break-all' }}>{x.replace('/app/PDF/', '')}</td>
                                                                <td className=''>
                                                                    <button
                                                                        className='btn btn-outline-danger mx-auto px-0 col-12 d-flex justify-content-center text-center'
                                                                        value={x}
                                                                        style={{maxWidth:'30px'}}
                                                                        onClick={(e) => {console.log(x,index,"----966");handleRemoveClick(e,x,index); setLoadingIndex(index); }}
                                                                    >
                                                                        {loadingIndex ===  index ? (
                                                                            <ThreeDots type="Oval" position="top-center" color="#fff"height={20} width={20} />
                                                                        ) : (
                                                                            <MdDeleteForever />
                                                                        )}
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )):'N/A'}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div> : ''
                        }
                        {
                            vis === 'Chatbot Preview' ?

                                <div className=''>

                                    <div className='px-3 col-12'>
                                        <h3 className="fs-4 d-flex justify-content-center container text-center mt-3 mb-1" style={{ color: "#FFFFFF" }}>
                                            Chatbot Preview
                                        </h3>
                                        <ChatUIDemo botID={id} />


                                    </div>

                                </div> : ''
                        }
                        {
                            vis === 'Bot Analytics' ?

                                <div >
                                    {/* <label className='fs-4 d-flex justify-content-center container text-center mb-3' style={{ height: '100%', color: '#FFFFFF' }} >Analytics</label> */}
                                    <label className='fs-4 d-flex justify-content-center container text-center mt-5 mb-5' style={{ height: '100%', color: '#FFFFFF' }} >Tokens used</label>
                                    <div className='fs-4 d-flex justify-content-center  text-center mt-1 mb-5'>

                                        <div className="input-group mb-3 mx-2 " style={{ width: '400px' }}>
                                            <input type="text" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} className="form-control col-lg-6" placeholder="YYYY or YYYY-MM" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => handleCreateGraph()}>Generate Graph</button>
                                        </div>

                                    </div>
                                    <div className='fs-4 col-12 d-flex justify-content-center container text-center mb-5 mt-3' style={{ color: '#FFFFFF' }}>
                                        {tokenDataGraph.length === 0 ? '' :
                                            <ResponsiveContainer width="99%" minHeight={minHeight}>
                                                <LineChart width={1000} height={500} data={tokenDataGraph} margin={{ top: 5, right: 20, bottom: 5, left: 10 }}>
                                                    <Line type="monotone" dataKey="usage" stroke="#8884d8" />
                                                    <XAxis dataKey="date"
                                                        minTickGap={10}
                                                        style={{ fontSize: '14px' }}
                                                    />
                                                    <YAxis style={{ fontSize: '14px' }} allowDataOverflow={true} />
                                                    <Tooltip content={<CustomTooltip />} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                    </div>

                                    <label className='fs-4 d-flex justify-content-center container text-center mt-5 mb-5' style={{ height: '100%', color: '#FFFFFF' }} >Unique Conversations</label>
                                    <div className='fs-4 d-flex justify-content-center  text-center mt-1 mb-5'>

                                        <div className="input-group mb-3 mx-2 " style={{ width: '400px' }}>
                                            <input type="text" value={selectedTimeCon} onChange={(e) => setSelectedTimeCon(e.target.value)} className="form-control col-lg-6" placeholder="YYYY or YYYY-MM" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => handleCreateGraphCon()}>Generate Graph</button>
                                        </div>

                                    </div>
                                    <div className='fs-4 col-12 d-flex justify-content-center container text-center mb-5 mt-3' style={{ color: '#FFFFFF' }}>
                                        {conDataGraph.length === 0 ? '' :
                                            <ResponsiveContainer width="99%" minHeight={minHeight}>
                                                <LineChart width={1000} height={500} data={conDataGraph} margin={{ top: 5, right: 20, bottom: 5, left: 10 }}>
                                                    <Line type="monotone" dataKey="ConNo" stroke="#8884d8" />
                                                    <XAxis dataKey="date"
                                                        minTickGap={10}
                                                        style={{ fontSize: '14px' }}
                                                    />
                                                    <YAxis style={{ fontSize: '14px' }} allowDataOverflow={true} />
                                                    <Tooltip content={<CustomTooltip2 />} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                    </div>

                                    <div className='fs-4 col-12 row d-flex justify-content-center text-center  mb-5 mt-3 mx-1 ' style={{ color: '#FFFFFF' }}>
                                        <label className='fs-4 d-flex justify-content-center  col-11 text-center mt-5 mb-5 mx-2' style={{ height: '100%', color: '#FFFFFF' }}>Chat History</label>

                                        <div className=' d-flex justify-content-center fs-4'>
                                            <div
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                className={` ${currentPage === 1 ? 'disabled' : ''}`}
                                            >
                                                <BsFillArrowLeftCircleFill style={{ color: 'white' }} />
                                            </div>
                                            <p className='d-flex  justify-content-center  text-center mb-1 mt-1 mx-4' style={{ color: '#FFFFFF' }}>
                                                Page {currentPage}
                                            </p>
                                            <div
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                className={` ${endIndex >= filterHistory.length ? 'disabled' : ''}`}
                                            >
                                                <BsFillArrowRightCircleFill style={{ color: 'white' }} />
                                            </div>
                                        </div>

                                        <input className='fs-4 col-sm-8 col-lg-10 col-11 d-flex justify-content-center rounded-4  mt-4 text-center mb-3 ' type="text"
                                            placeholder="Search..."
                                            value={searchTermPay}
                                            onChange={handleSearchPay}
                                        />
                                        {filterHistory === ''
                                            ? ''
                                            : itemsToDisplay.map((x, index) => (
                                                <Accordion
                                                    alwaysOpen='false'
                                                    key={index}
                                                    className='col-11 my-2 custom-accordion'
                                                    activeKey={openAccordionIndex === index ? '0' : null}
                                                    onSelect={() => toggleAccordion(index)}
                                                >
                                                    <Accordion.Item eventKey="0" style={{ backgroundColor: '#212529', border: '1px solid #4A5AB0' }}>
                                                        <Accordion.Header>
                                                            {x.time} {x.Name} {x.Email}
                                                            <span
                                                                style={{
                                                                    color: '#FFE459',
                                                                    position: 'absolute',
                                                                    right: '25px',
                                                                    top: '50%',
                                                                    fontSize: '34px',
                                                                    transform: 'translateY(-50%)',
                                                                }}
                                                            >
                                                                {openAccordionIndex === index ? '-' : '+'}
                                                            </span>
                                                        </Accordion.Header>
                                                        <Accordion.Body style={{ color: 'white' }}>
                                                            {x.history.map((message) => (
                                                                <div
                                                                    key={message.id}
                                                                    className={`message ${message.sender === 'me' ? 'sent' : 'received'}`}
                                                                    style={message.sender === 'me' ? messageStyleSend : messageStyleRec}
                                                                >
                                                                    <p style={{ color: 'white' }} className={`my-1 ${message.sender === 'me' ? 'text-end' : 'text-start'}`}>
                                                                        {message.text}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            ))}
                                    </div>




                                </div>

                                : ''
                        }

                    </div>
                </div>

            </div>

        </div>
    )
}

export default ManageBotDash