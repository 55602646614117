import React,{useState,useEffect} from 'react'
import { IoReorderThree } from "react-icons/io5";
import { Link, NavLink, useLocation } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { FaListUl } from 'react-icons/fa';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/Zema_Logo_Original.png'
import { ToastContainer, toast } from 'react-toastify';

const VerticalNavDash = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [cross, setCross] = useState(false)

    const [sideNavChoose, setSideNavChoose] = useState('Stats')

    const handleSideNav = (e) => {
      // e.preventDefault()
      console.log(e)
      setSideNavChoose(e)
    }
  
  
    useEffect(() => {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        setIsSmallScreen(screenWidth < 768);
        setCross(screenWidth < 768)
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <>
    <aside style={{ position: 'fixed', top: '0px', overflowY: 'auto', minHeight: '100vh', zIndex: '2', maxWidth: '268px',width:'220px', backgroundColor: 'black' }} className={`sidebar-dark-primary elevation-4 ${!isSmallScreen ? '' : 'd-none'}`}>

    <a className="brand-link" style={{ height: '55px' }}>
    </a>
    <div className={`d-flex m-2 ${isSmallScreen == false ? '' : 'd-none'}`} style={{ color: 'white' }} onClick={() => setIsSmallScreen(true)}>
      <RxCrossCircled className={`ms-auto ${cross ? '' : 'd-none'}`} />
    </div>


    <div className="sidebar">

      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar col-12 flex-column me-2" data-widget="treeview" role="menu" data-accordion="false">
          {/* <li className="nav-header col-12" style={{ color: 'white' }} onClick={()=>navigate('/')}>Podcast Summarize</li> */}
          <NavLink className="navbar-brand mx-auto" style={{ height: '85px', width: '85px' }} to="/"><img src={logo} style={{ height: '85px', width: '85px', borderRadius: '100%' }} className='mx-2 ' /></NavLink>
          <li className="nav-item col-12 d-flex flex-wrap">
            <button style={{ color: 'white' }} className="nav-link col-12 btn btn-link text-start"  >
              <FaListUl style={{ color: 'white', display: 'none' }} className='me-2' />
            </button>
            <li className="nav-item col-12">
<Nav.Link className='mx-3'> <Link to='/dashboard' className='fw-bolder  underline-grow  px-0 py-0' style={{  color: '#FFFFFF' }} >Dashboard</Link></Nav.Link>
            </li>
            <li className="nav-item col-12">
<Nav.Link className='mx-3'> <Link to='/tokenleftdash' className='fw-bolder  underline-grow  px-0 py-0' style={{  color: '#FFFFFF' }} >Token Usage</Link></Nav.Link>
            </li>
            <li className="nav-item col-12">
<Nav.Link className='mx-3'> <Link to='/conversationdash' className='fw-bolder  underline-grow  px-0 py-0' style={{  color: '#FFFFFF' }} >Chats</Link></Nav.Link>
            </li>
            <li className="nav-item col-12">
<Nav.Link className='mx-3'> <Link to='/failedconversationdash' className='fw-bolder  underline-grow  px-0 py-0' style={{  color: '#FFFFFF' }} >Failed Chats</Link></Nav.Link>
            </li>
            <li className="nav-item col-12">
<Nav.Link className='mx-3'> <Link to='/mychatbotsdash' className='fw-bolder  underline-grow  px-0 py-0' style={{  color: '#FFFFFF' }} >My Chatbots</Link></Nav.Link>
            </li>
            <li className="nav-item col-12">
<Nav.Link className='mx-3'> <Link to='/createchatbotdash' className='fw-bolder  underline-grow  px-0 py-0' style={{  color: '#FFFFFF' }} >Create Chatbot</Link></Nav.Link>
            </li>
           
          </li>
          {/* <li className="nav-item col-12 mt-5 d-flex flex-wrap">
            <button value='Podcasts Issues' onClick={(e) => { handleLogout() }} className="fs-6  text-center mx-auto col-11 btn btn-outline-primary">
              Log Out
            </button>
          </li> */}
        </ul>
      </nav>
    </div>
  </aside>
   <IoReorderThree size={42} style={{ top: '10px', left: '10px', position: 'fixed', zIndex: '2', color: 'black' }} onClick={() => setIsSmallScreen(false)} />
   <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
   </>
  )
}

export default VerticalNavDash